
import React, {useState, useEffect} from "react"
import Layout from "../../layout"

import WebappForm from "../../webapps/form"
import SubmitDataField from "../fields/submitdata"

const formatTools = require("../../../../lib/formatTools")

const negosyonowAPI = require("../../../../lib/requestNegosyownow")
const webappsAPI = require("../../../../lib/requestWebapps")
const onpremAPI = require("../../../../lib/requestOnprem")

const ENTITYID="nnpayment"

const nnpaymentstatuslist = [
	{"value":1,"display":"Submitted / For Clearing"},
	{"value":2,"display":"Cancelled"},
	{"value":3,"display":"For Review / Checking"},
	{"value":4,"display":"Cleared"},
];


const nnpaymentstatusforreviewlist = [
	{"value":1,"display":"Submit for Clearing"},
	{"value":2,"display":"Cancel / Invalid Submission"},
	{"value":3,"display":"For Review / Checking"},
];


const PaymentEditorPage = ({location, usagetypeid, docrefnumlabel, formnotesfieldlabel, customSearchFilter}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [userformurl, setUserformurl] = useState("");
	const [userformdata, setUserformdata] = useState({});

	const [refnumid, setRefnumid] = useState(0);


	const searchFields = [
		{"label":"Last Update", "dbfield": "nnpayment_updated", "type": "datetime", "filtertype": "datetime"},
		{"label":"Payment Date", "dbfield": "nnpayment_date", "type": "datetime", "filtertype": "datetime"},
		{"label":"Validation Ref #", "dbfield": "nnpayment_validationref", "type": "text", "filtertype": "text"},
		{"label":"Status", "dbfield": "nnpaymentstatus_id", "type": "combo", "filtertype": "combo", "options": nnpaymentstatuslist},
		{"label":docrefnumlabel, "dbfield": "nnpayment_purposerefnum", "type": "text", "filtertype": "text"},
		{"label":"Name", "dbfield": "nnpayment_notes", "type": "text", "filtertype": "text"},
		{"label":"Amount", "dbfield": "nnpayment_amount", "type": "currency", "filtertype": "currency"},
		{"label":"Status", "dbfield": "nnpaymentstatus_name", "type": "text", "filtertype": "text"},
		{"label":"Notes", "dbfield": "nnpayment_cancelreason", "type": "text", "filtertype": "text"},
	];


	const formFields = [
		[
			{"label":"Last Update", "field": "nnpayment_updated", "value": "", "input": "datetime", "mode": "readonly"},
			{"label":"Payment Date", "field": "nnpayment_date", "value": "", "input": "datetime", "mode": "readonly"},
			{"label":"Validation Ref #", "field": "nnpayment_validationref", "value": "", "input": "textbox", "mode": "readonly", "info":"Provided by payment clearing team like Onprem"},
			{"label":docrefnumlabel, "field": "nnpayment_purposerefnum", "value": "", "input": "textbox", "mode": "readonly", "info":"Use as reference # in payment"},
			{"label":"Details", "field": "nnpayment_submiturl", "value": "", "input": "textarea", "mode": "readonly"},
			{"label":"Status", "field": "nnpaymentstatus_id", "value": "", "input": "combo", "mode": "required", "options": nnpaymentstatuslist},
			{"label":"Notes", "field": "nnpayment_cancelreason", "value": "", "input": "textarea", "mode": "normal"},
			{"label":"Refnum", "field": "nnpayment_purposerefnum", "value": "", "input": "hidden", "mode": "readonly"},
		]
	];

	useEffect(() => {
		function loadFormdata(formurl)
		{
			negosyonowAPI.loadFormData(formurl, token).then(dataresponse => {
				setUserformdata(dataresponse);
			});
		}

		loadFormdata(userformurl)
	}, [userformurl, token]);

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		var nnpaymentstatus_id = -1;
		//callback({"status":"Error", "message":"Debug"}); return;
		if (params.hasOwnProperty("nnpaymentstatus_id")) {
			if (params.nnpaymentstatus_id) {
				nnpaymentstatus_id = parseInt(params.nnpaymentstatus_id, 10);
			}
		}
		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}
		params.nnpayment_updated=formatTools.getDateStr();
		if (nnpaymentstatus_id === 1) {
			// Submit for Clearing
			relayPayment(function(relayresponse) {
				if (relayresponse.status === "OK") {
					if (relayresponse.hasOwnProperty("documentid")) {
						if (relayresponse.documentid > 0) {
							// add/update fields to params to reflect payment info/status
							params.apiuser_id = 3; // Switch to API user OnPrem
							params.nnpayment_validationref = ""+relayresponse.documentid;
							while (params.nnpayment_validationref.length < 8) {
								params.nnpayment_validationref = "0" + params.nnpayment_validationref;
							}
							if (relayresponse.statusid === 2) {
								params.nnpayment_datevalidated = relayresponse.statusdate;
								params.nnpayment_validated = 1;
								params.nnpaymentstatus_id = 4;
							}
						}
					}

					webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(updateresponse) {
						if (updateresponse.status === "OK") {
							updatePaymentData(pkid, callback);
							return;
						}
						callback(updateresponse);
					});
					return;
				}
				callback(relayresponse);
			});
			return;
		} // submit for clearing

		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				if (nnpaymentstatus_id === 2) {
					// Payment rejected, update User data
					updatePaymentData(pkid, callback);
					return;
				}
			}
			callback(response);
		}); // save record

	}

	function updatePaymentData(pkid, callback)
	{
		negosyonowAPI.buildOrder(token, "payment", {
			"action": "recompute",
			"data": {"pkid":pkid},
			"type": "sns"
		}).then(buildresponse => {
			callback({
				"status":"OK",
				"message":"Updating records"
			});
		});
	}

	function relayPayment(callback)
	{
		var paymentdate = "";
		var paymentamount = 0;
		var paymentnotes = "";
		var payor = "";
		var receiptfname = "";
		var receiptbase64 = "";
		var receipttype = "";
		var label = "";
		var tmpidx = 0;

		var idx = 0;
		while (idx < userformdata.fields.length) {
			label = userformdata.fields[idx].label;
			tmpidx = label.indexOf(" ");
			if (tmpidx > 0) {
				label = label.substring(0, tmpidx);
			}
			if (userformdata.fields[idx].label === formnotesfieldlabel) {
				paymentnotes = userformdata.fields[idx].value;
			} else if (userformdata.fields[idx].label === "Full Name") {
				payor = userformdata.fields[idx].value;
			} else if (userformdata.fields[idx].label === "Amount") {
				paymentamount = parseFloat(userformdata.fields[idx].value);
			} else if (label === "Date/Time") {
				paymentdate = userformdata.fields[idx].value;
			} else if (label === "Source") {
				receipttype = userformdata.fields[idx].value;
			} else if (label === "Receipt") {
				if (userformdata.fields[idx].value.hasOwnProperty("fname") && userformdata.fields[idx].value.hasOwnProperty("data")) {
					receiptfname = userformdata.fields[idx].value.fname;
					receiptbase64 = userformdata.fields[idx].value.data;
				}
			}
			idx++;
		}
		if (receiptbase64.length < 1 || receiptfname.length < 1) {
			callback({"status":"Error", "message":"No receipt provided"});
			return;
		}

		onpremAPI.addPayment({
			"doctypeid": typeof usagetypeid !== "undefined"?usagetypeid:4, 					// 1:bus,2:pickup,3:delivery,4:seminar,5:course
			"bankcode": "CBC",					// TODO: hardcoded for now
			"bankacccountno": "20-7043878-1",	// TODO: hardcoded for now

			"paymentdate":paymentdate,
			"amount":paymentamount,
			"docrefno": ""+refnumid,
			"payor":payor,
			"notes":paymentnotes, // e.g. seminar title/name

			"receiptfname":receiptfname,
			"receiptbase64":receiptbase64,
			"receipttype":receipttype,
		}).then(response => {
			// Update nnpayment
			callback(response);

		});

	}

	function customValidateValues(mainform, subformlist, footerformlist)
	{
		var statusidval = "";
		var summaryval = "";
		var idx = 0;
		while (idx < mainform.length) {
			if (mainform[idx].field === "nnpaymentstatus_id") {
				statusidval = parseInt(""+mainform[idx].value,10);
			} else if (mainform[idx].field === "nnpayment_cancelreason") {
				summaryval = mainform[idx].value;
			} else if (mainform[idx].field === "nnpayment_purposerefnum") {
				setRefnumid(parseInt(mainform[idx].value, 10));
			}
			idx++;
		}
		if (statusidval === 2 && summaryval.length < 1) {
			return {"status":"Error", "message":"Please provide reason for cancelling in the deposit"};
		}
		return {"status":"OK"};
	}

	function customEditFieldInfo(inputlist, idx)
	{
		var tmpfield = {};
		const fieldlist = ["nnpaymentstatus_id", "nnpayment_cancelreason"];
		if (!fieldlist.includes(inputlist[idx].field)) {
			return inputlist[idx];
		}
		var statusmodified = false;
		var statusidval = 0;
		var tmpidx = 0;

		while (tmpidx < inputlist.length) {
			if (inputlist[tmpidx].field === "nnpaymentstatus_id") {
				if (inputlist[tmpidx].modified) {
					statusmodified = true;
				}
				statusidval = parseInt(""+inputlist[tmpidx].value,10);
				break;
			}
			tmpidx++;
		}
		if (statusidval === 3 || statusmodified) {
			// (3) For Review
			if (inputlist[idx].field === "nnpaymentstatus_id") {
				tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				tmpfield.info = "Check if valid payment information before submitting for clearing";
				tmpfield.options = nnpaymentstatusforreviewlist;
				return tmpfield;
			}
			return inputlist[idx];
		}

		tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
		tmpfield.mode = "readonly";
		return tmpfield;
	} // customEditFieldInfo


	function customFieldFragment(inputlist, idx, isdisabled, fieldid, inputchangecallback)
	{
		if (inputlist[idx].field === "nnpayment_submiturl") {
			if (inputlist[idx].value) {
				if (inputlist[idx].value !== "") {
					setUserformurl(inputlist[idx].value);
					return displayFormData(inputlist[idx].label, userformdata);
				}
			} else {
				return <></>
			}
		}
		return null;
	}

	function displayFormData(title, formdata)
	{
		const labelformatlist = {
			"Date/Time of Deposit":"datetime",
			"Amount":"currency"
		};
		return <SubmitDataField
					datatitle={title}
					formdata={formdata}
					labelformatlist={labelformatlist}
				/>
	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowadd={false}
				allowdelete={false}
				mobilerowtitlefield={["nnpayment_notes"]}
				searchFields={searchFields}
				editFields={formFields}
				userSearchParam={{"orderby":"nnpayment_updated desc"}}
				userSearchFilter={customSearchFilter}

				customSubmit={customSubmit}
				customFieldFragment={customFieldFragment}
				customEditFieldInfo={customEditFieldInfo}
				customValidateValues={customValidateValues}

				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default PaymentEditorPage;

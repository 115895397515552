
import React from "react"

import PaymentEditorPage from "../../components/negosyonow/controls/paymenteditor"


const SeminarpaymentsPage = ({location}) => {

	return <PaymentEditorPage
				location={location}
				usagetypeid={4}
				docrefnumlabel={"Seminar Registration ID"}
				formnotesfieldlabel={"Seminar Name"}
				customSearchFilter={[{"field":"nnpayment_purpose", "operation":"=", "value": "SEMINARFEE"}]}
			/>

}


export default SeminarpaymentsPage;
